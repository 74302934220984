
<template>
  <div>
    <div class="app-header" >
      违规记录编辑
      <div class="header-left" @click="$router.push({path:'/eq-violatrecord'})"><i class="iconfont icon-prev"></i></div>
      <div class="header-right" @click="goHome()"><i class="go-home"></i></div>
    </div>
    <Panel class="tabs">
      <template slot="title">
        <div :class="`tab${tabIndex===0?' active':''}`" @click="switchLicenses(0)">基本信息</div>
        <div :class="`tab${tabIndex===1?' active':''}`" @click="switchLicenses(1)">处理信息</div>
      </template>
      <div slot="content"  >
        <div class="flex-dir-row mt15" v-if="tabIndex===0">
          <label class="line-label view1"><span style="color: red">*</span>违规人员</label>
          <div class="flex-item-auto">
<!--            <input class="line-input" @click="showType" v-model="violateUserName"-->
<!--                   @keydown="preventDefault"/>-->
            <input class="line-input" @click="showViolateUserName" placeholder="违规人员" v-model="violateUserName"
                   />
          </div>
        </div>
        <div class="flex-dir-row mt15" v-if="tabIndex===0">
          <label class="line-label view1"><span style="color: red">*</span>违规时间</label>
          <div class="flex-item-auto">
            <input class="line-input" @click="openPicker" v-model="violateDate" placeholder="违规时间"
            />
          </div>
        </div>
        <div class="flex-dir-row mt15" v-if="tabIndex===0">
          <label class="line-label view1">设备名称</label>
          <div class="flex-item-auto">
            <input class="line-input" id="eqName" @click="showeqName" v-model="eqName"
                   @keydown="preventDefault" placeholder="设备名称"/>
          </div>
        </div>
        <div class="flex-dir-row mt15" v-if="tabIndex===0">
          <label class="line-label view1">违规地点</label>
          <div class="flex-item-auto">
            <input class="line-input"  @click="showroomName" v-model="roomName"
                   @keydown="preventRoom" placeholder="违规地点"/>
          </div>
        </div>
        <div class="flex-dir-row mt15" v-if="tabIndex===0">
          <label class="line-label view1">违规分类</label>
          <div class="flex-item-auto">
            <input class="line-input"  @click="showviolateType" v-model="violateTypeName"
                   @keydown="preventviolateType" placeholder="违规分类"/>
          </div>
        </div>
        <div class="flex-dir-row mt15" v-if="tabIndex===0">
          <label class="line-label view1" >违规描述</label>
          <div class="flex-item-auto">
            <textarea class="line-input" rows="4" style="height: 70px" id="" maxlength="1000" v-model="remark" placeholder="违规描述"
            />
          </div>
        </div>
        <div class="flex-dir-row mt15" v-if="tabIndex===0" style="position:relative;">
          <label class="line-label view1" style="position: absolute;top: 8px" >相关文件</label>
          <FileUpload2 space="jsbFile" @showfile1list="gettabIndex" style="position: absolute;right: 0.20rem" :tabindex="0" max-size="10" ref="fileUpload2" :editable="true" class="payImg1"/>

        </div>
        <div class="flex-dir-row mt15" v-if="tabIndex===1">
          <div style="display: inline-block;visibility: hidden">&nbsp;&nbsp;aa</div>
            <mt-radio
                title="是否处理"
                v-model="handled"
                :options=violateSolves>
            </mt-radio>
        </div>

        <div class="flex-dir-row mt15" v-if="tabIndex===1">
          <label class="line-label view1" >处理结果</label>
          <div class="flex-item-auto">
            <textarea class="line-input" rows="4" style="height: 70px" placeholder="处理结果"  maxlength="1000" v-model="handledResult"
            />
          </div>
        </div>
        <div class="flex-dir-row mt15" v-if="tabIndex===1" style="position:relative;">
          <label class="line-label view1" style="position: absolute;top: 8px">相关文件</label>
          <FileUpload2 space="jsbFile" max-size="10" style="position: absolute;right: 0.20rem" @showfile2list="gettabIndex" :tabindex="1" ref="fileUpload2" :editable="true" class="payImg1"/>
        </div>
      </div>

    </Panel>
    <div class="btn footer0" style="background-color: white;color: black!important; border: 1px #E6E6E6 solid" @click="cancel">取消</div>
    <div class="btn  green footer1" @click="save">保存</div>
    <mt-popup
        v-model="showTypeVisible"
        position="bottom"
    >
      <mt-picker :slots="typeSlots" @change="ontypeChange" value-key="text" v-if="showTypeVisible"></mt-picker>
    </mt-popup>

    <mt-popup
        v-model="showdictRooms"
        position="bottom"
    >
      <mt-picker :slots="dictRooms" @change="onDictRoomsChange" value-key="text" v-if="showdictRooms"></mt-picker>
    </mt-popup>
    <mt-popup
        v-model="showviolateTypes"
        position="bottom"
    >
      <mt-picker :slots="dictViolateTypes" @change="onViolateTypesChange" value-key="text" v-if="showviolateTypes"></mt-picker>
    </mt-popup>
    <mt-popup
        v-model="showeqNames"
        position="bottom"
    >
      <mt-picker :slots="eqNames" @change="ontypeEqChange" value-key="text" v-if="showeqNames"></mt-picker>
    </mt-popup>

    <mt-datetime-picker
        ref="picker"
        type="datetime"
        @confirm="format"
        v-model="violateDate1">
    </mt-datetime-picker>
    <!--下拉输入框-->
    <mt-popup
        v-model="addViolateUserName"
        position="top"
        v-if="addViolateUserName"
    >

      <select-search v-model="selectViolateUserName"
                     placeholder="违规人员"
                     @visible="getvisible"
                     @closevisible="closevisible"

      >
        <selectcell
            v-for="item in filterResult"
            :title="item.text"
            @click.native="selectserach(item)"
        >
        </selectcell>
      </select-search>


    </mt-popup>



  </div>
</template>
<style lang="scss">

.picker-selected{
  font-weight: bold;
}

.exp-line-title {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  margin-top: 0.10rem ;
}

.exp-line-status {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 400;
  color: #3b5998;
}

.finish-panel {
  .panel-title {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 0 15px;
  }

  .panel-content {
    padding: 15px;
  }

}
.wrap{
  display:flex;
  flex-direction:column;
  height:100%;
}
.footer0{
  height:40px;
  width: 50%;
  position: fixed;
  bottom:0;
  left:0;
  z-index: 10;
}
.footer1{
  height:40px;
  width: 50%;
  position: fixed;
  bottom:0;
  left:50%;
  z-index: 10;
}

.view1{
  color: black;
  margin-right: 8px;
  font-size: 0.14rem;
}
.mint-search-list{
  position: inherit;
  padding-top: 2px;
}
.mint-radiolist-title{
  display: inline-block;
  color: black;
  font-size: 14px;

}
.app-header {
  height: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  //border-bottom: solid 1px rgba(0, 0, 0, 0.03);
  //box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.08);
  z-index: 2;

  font-size: 16px;
  color: #000;
  font-weight: bold;
}

.header-left, .header-right {
  height: 100%;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

}

.header-left {
  left: 0;
}

.header-right {
  right: 0;
}
.icon-left {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
}

.go-home {
  display: block;
  width: 21px;
  height: 19px;
  background: url("../assets/images/go-home.png");
  background-size: contain;
}
.mint-cell{
  display: inline-block;
}

.payImg1 {

  right: 0;
  top: 50px;
  display: inline-block;

  .grid-cell-1-3{
    width: 100px;
    height: 100px;
  }
}
.line-label{
  font-size: 0.14rem;
}
</style>
<script>
import AppHeader from "@/components/AppHeader";
import {InfiniteScroll , Radio, DatetimePicker} from 'mint-ui';
import {Toast, MessageBox} from 'mint-ui';
import dict from "@/plugins/dict";
import store from "@/plugins/store";
import Panel from "@/components/Panel";
import {mapState} from "vuex";
import FileUpload2 from "@/components/FileUpload2";
import selectSearch from "@/components/selectSearch"
import selectcell from "@/components/selectcell"
export default {
  components: {
    Panel,
    AppHeader,
    InfiniteScroll,
    Radio,
    DatetimePicker,
    dict,
    FileUpload2,
    selectSearch,
    selectcell,
  },
  data() {
    return {

      page: 1,
      tabIndex: 0,
      violateUserId: null,
      violateUserName: null,
      selectViolateUserName: null,
      eqName: null,
      eqId: null,
      roomName: null,
      roomId: null,
      violateTypeName: null,
      violateTypeCode: null,
      violateDate: null,
      violateDate1: new Date(),
      remark: null,
      handledResult: null,
      responseResult: null,
      handled: "false",
      loading: false,
      noMore: false,
      showTypeVisible: false,
      showdictRooms: false,
      showviolateTypes: false,
      showeqNames: false,
      id: null,
      //数据字典
      users: [],
      equipments: [],
      rooms: [],
      violateTypes: [],
      //文件上传
      file1Ary: [],
      file2Ary: [],
      addViolateUserName: false,
      exist: true
    }
  },

  computed:
      {
        ... mapState({
          // 为了能够使用 `this` 获取局部状态，必须使用常规函数
          violateSolves(state) {
            var violateSolves=[]
            var arys= state.violateSolves
            for (var i=0;i<arys.length;i++){
              var ary= {
                label: arys[i].text,
                value: arys[i].id
              }
              violateSolves.push(ary)
            }
            return violateSolves;
          },
          typeSlots(state) {

            this.users=state.users;
            return [{values: state.users}]
          },
          dictRooms(state) {
            this.rooms=state.dictRooms;
            return [{values: state.dictRooms}]
          },
          dictViolateTypes(state){
            this.violateTypes=state.violateTypes;
            return [{values: state.violateTypes}]
          },
          eqNames(state) {
            this.equipments=state.equipments;
            return [{values: state.equipments}]
          },


        }),

        filterResult() {
          // for ()
          var a= this.users.filter(value => new RegExp(this.selectViolateUserName).test(value.text))
          return a;
        }
      },


  methods: {
    initAddEventFeedback(){
      this.id=this.$route.query.violatrecordId;
      this.page = 1
      this.noMore = false
      if(this.id!=null){
        this.loadEventFeedback()
      }

    },
    showViolateUserName(){
      this.addViolateUserName=true
      this.selectViolateUserName=this.violateUserName
    },
    loadEventFeedback(){
      if (this.noMore) {
        return
      }
      const that = this
      this.loading = true
      let data = new FormData()
      data.append('id',`${this.id}`)
      this.$http.post(`/api/equipmentViolatrecord/edit`, data).then(res=>{
        var data=res.data
        if (data){
          this.exist=data.exist
          if(data.violateUser!=null){
            if(data.exist){
              that.queryuserById(data.violateUser)
            }else {
              that.violateUserName=data.violateUserName
            }

          }
          if(data.violateDate!=null){
            var a= this.$moment(data.violateDate).format('YYYY-MM-DD HH:mm:ss')
            that.violateDate=a
          }
          if(data.equipmentId!=null){
            that.queryEqNameById(data.equipmentId)
          }
          if(data.roomId!=null){
            that.queryRoomNameById(data.roomId)
          }
          if(data.violateType!=null){
            that.queryviolateTypeByCode(data.violateType)
          }
          that.handled=data.handled.toString()
          if(data.remark!=null){
            that.remark=data.remark
          }


          //文件上传
          if(data.fileId1!='null'&&data.fileId1!='[]'){
            var fileId1s=(data.fileId1.slice(2,-2)).replace(/","/g,",")
            var fileId1sAry=fileId1s.split(",")
            that.file1Ary=fileId1sAry
            that.$refs.fileUpload2.initFilesByUuids(fileId1sAry,this.tabIndex)
          }

          if(data.fileId2!='null'&&data.fileId2!='[]'){
            var fileId2s=(data.fileId2.slice(2,-2)).replace(/","/g,",")
            var fileId2sAry=fileId2s.split(",")
            that.file2Ary=fileId2sAry
            // that.$refs.fileUpload2.initFilesByUuids(fileId2sAry)
          }


          if(data.handledResult!=null){
            that.handledResult=data.handledResult
          }

        }else {
          this.noMore = true
        }
      })
    },

    closevisible(){
      this.addViolateUserName=false
    },
    getvisible(d){
      this.addViolateUserName=false
      var isexist=true
      if(this.selectViolateUserName!=null&&this.selectViolateUserName!=''){
        for (var i=0;i<this.users.length;i++){
          if(d==this.users[i].text){
            this.violateUserName=this.users[i].text
            this.violateUserId=this.users[i].id
            this.exist=true
            isexist=false
            break;
          }
        }
        if(isexist){
          this.violateUserId=null
          this.violateUserName=this.selectViolateUserName
        }
      }
    },
    selectserach(item) {
      this.selectViolateUserName = item.text
      // this.feedbackUserId=item.id
    },
    queryuserById(id){
      this.typeSlots;
      var eqAry= this.users
      if(eqAry.length>0){
        for (var i=0;i<eqAry.length;i++){
          if(id==eqAry[i].id){
            this.violateUserName=eqAry[i].text
            this.violateUserId=eqAry[i].id
          }
        }
      }
    },
    gettabIndex(data){
      var files=data.files
      if(data.tabIndex==0){
        this.file1Ary=[]
        for (var i=0;i<files.length;i++){
          if(files[i].fid!=""&&files[i].fid!=null){
            this.file1Ary.push(files[i].fid)
          }

        }
      }
      if(data.tabIndex==1){
        this.file2Ary=[]
        for (var i=0;i<files.length;i++){
          if(files[i].fid!=""&&files[i].fid!=null){
            this.file2Ary.push(files[i].fid)
          }

        }
      }
    },

    queryEqNameById(id){
      this.eqNames;
      var eqAry= this.equipments
      if(eqAry.length>0){
        for (var i=0;i<eqAry.length;i++){
          if(id==eqAry[i].id){
            this.eqName=eqAry[i].text
            this.eqId=eqAry[i].id
          }
        }
      }
    },
    queryRoomNameById(id){
      this.dictRooms;
      var eqAry= this.rooms
      if(eqAry.length>0){
        for (var i=0;i<eqAry.length;i++){
          if(id==eqAry[i].id){
            this.roomName=eqAry[i].text
            this.roomId=eqAry[i].id
          }
        }
      }
    },
    queryviolateTypeByCode(code){
      this.dictViolateTypes;
      var eqAry= this.violateTypes
      if(eqAry.length>0){
        for (var i=0;i<eqAry.length;i++){
          if(code==eqAry[i].id){
            this.violateTypeName=eqAry[i].text
            this.violateTypeCode=eqAry[i].id
          }
        }
      }
    },

    ontypeChange(picker, values) {
      this.violateUserName = values[0].text
      this.violateUserId = values[0].id
    },
    onDictRoomsChange(picker, values) {
      this.roomName = values[0].text
      this.roomId = values[0].id
    },

    onViolateTypesChange(picker, values) {
      this.violateTypeName = values[0].text
      this.violateTypeCode = values[0].id
    },
    ontypeEqChange(picker, values) {
      this.eqName = values[0].text
      this.eqId = values[0].id
    },
    goHome() {
      this.$router.push("/")
    },

    switchLicenses(tabIndex){
      var that=this;
      this.tabIndex=tabIndex;
      setTimeout(function (){
        if(tabIndex==0){

          that.$refs.fileUpload2.initFilesByUuids(that.file1Ary,tabIndex)
        }
        if(tabIndex==1){

          that.$refs.fileUpload2.initFilesByUuids(that.file2Ary,tabIndex)
        }
      },150)

    },
    showType(){
      this.showTypeVisible = true
    },
    showeqName(){
      this.showeqNames = true
    },
    showroomName(){
      this.showdictRooms=true
    },
    showviolateType(){
      this.showviolateTypes=true
    },

    preventDefault(e) {
      e.preventDefault()
    },
    preventRoom(e){
      e.preventRoom()
    },
    preventviolateType(e){
      e.preventviolateType()
    },

    openPicker() {
      this.$refs.picker.open();
    },
    openPickerNext() {
      this.$refs.pickerNext.open();
    },
    format(val){
      this.violateDate=this.$moment(val).format('YYYY-MM-DD HH:mm:ss')
    },

    save(){

      if(this.violateUserName!=null&&this.violateUserId==null){
        this.violateUserId=this.violateUserName
        this.exist=false
      }
      if(this.violateUserId!=null&&this.violateDate!=null){
        const that = this
        this.loading = true
        let data = new FormData()
        var file1= JSON.stringify(this.file1Ary)
        var file2= JSON.stringify(this.file2Ary)

        data.append('fileId1',file1)
        data.append('fileId2',file2)
        data.append('exist',`${this.exist}`)
        this.id&&data.append('id',`${this.id}`)
        this.violateUserId&&data.append('violateUser',`${this.violateUserId}`)
        this.violateDate&&data.append('violateDate',`${this.violateDate}`)
        this.eqId&&data.append('equipmentId',`${this.eqId}`)
        this.roomId&&data.append('roomId',`${this.roomId}`)
        this.violateTypeCode&&data.append('violateType',`${this.violateTypeCode}`)
        this.remark&&data.append('remark',`${this.remark}`)
        data.append('handled',`${this.handled}`)
        this.handledResult&&data.append('handledResult',`${this.handledResult}`)

        this.$http.post(`/api/equipmentViolatrecord/save`, data).then(resp => {
          if (resp.success) {
            Toast("提交成功！")
            setTimeout(function (){
              that.$router.push({path: '/eq-violatrecord'})
            },200)
          }
        })
      }else {
        Toast("必填项不能为空")
      }


    },
    cancel(){
      this.$router.push({path: '/eq-violatrecord'})
    }


  },
  mounted() {
    this.initAddEventFeedback()
    this.tabIndex = parseInt(this.$route.query.tabIndex || '0')
    this.switchLicenses(this.tabIndex)
    this.typeSlots
  }


}
</script>
