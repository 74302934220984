<style>
.mint-search-list{
  overflow: auto;
  max-height: 50%;
}
.searchheader{
  position: static!important;
}
.mint-searchbar{
  background-color: white;
  border-color: #97a4af;
}
.search-input{
  height: 0.35rem;
  border-radius: 10px;
  background-color: rgb(242,242,242);
  margin-left: 0.1rem
}
</style>
<template>

  <div class="mint-search ">
    <div class="app-header searchheader" >
      搜索人员
      <div class="header-left" @click="closesearch"><i class="iconfont icon-prev"></i></div>
      <div class="header-right" @click="goHome()"><i class="go-home"></i></div>
    </div>
    <div class="mint-searchbar">
      <div class="mint-searchbar-inner" >
        <input
            ref="inputfouce"
            @click="visible = true"
            type="search"
            v-model.trim="currentValue"
            :placeholder="placeholder"
            class="mint-searchbar-core search-input"
        >
      </div>
      <a
          class="mint-searchbar-cancel"
          @click="visiblesearch"
          v-show="visible"
          v-text="sureText">
      </a>
    </div>
    <div class="mint-search-list" v-show="show || currentValue">
      <div class="mint-search-list-warp">
        <slot>
          <selectcell v-for="(item, index) in result" :key="index" :title="item"></selectcell>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import selectcell from '@/components/selectcell';
if (process.env.NODE_ENV === 'component') {
  require('mint-ui/packages/cell/style.css');
}

export default {
  name: 'selectSearch',

  data() {
    return {
      visible: false,
      currentValue: this.value
    };
  },

  components: { selectcell },

  watch: {
    currentValue(val) {
      this.$emit('input', val);
    },

    value(val) {
      this.currentValue = val;
    }
  },

  props: {
    value: String,
    autofocus: Boolean,
    show: Boolean,
    cancelText: {
      default: '取消'
    },
    sureText:{
      default: '确定'
    },
    placeholder: {
      default: '搜索'
    },
    result: Array
  },
methods:{
  visiblesearch(){
    this.$emit("visible",this.currentValue);
  },
  closesearch(){
    this.$emit("closevisible");
  },
  goHome() {
    this.$router.push("/")
  },
},

  mounted() {
    var that=this
    this.$nextTick(function (){
      this.visible=true
      that.$refs.inputfouce.focus();
    })

  },
}
</script>